<template>
    <div>
        <b-card>
            <b-card-text>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti architecto minima et ea soluta! Esse obcaecati voluptatem tenetur minima quisquam ea sint aliquam ab voluptatum consequatur repudiandae similique delectus error suscipit numquam quibusdam blanditiis at explicabo vero eveniet, excepturi doloremque ratione est? Autem ipsa error illum voluptatibus et soluta fuga quaerat quos cupiditate voluptate, voluptatem hic tempore vero consequuntur neque repudiandae saepe rerum quasi laborum. Et tenetur sequi quod mollitia quia odit asperiores, doloribus odio aliquid delectus accusantium vel, corrupti ut soluta. Assumenda recusandae repudiandae nostrum. Rerum laboriosam voluptatibus hic dolores sapiente ducimus! Dolore, pariatur exercitationem dolorum ex assumenda voluptatibus.
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardText
} from "bootstrap-vue";

export default {
    components: {
        BCard,
        BCardText
    }
}
</script>

<style lang="scss">
</style>
